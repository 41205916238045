import { PublicKey } from '@solana/web3.js'

export const DXL_MINT_ADDRESS = new PublicKey('GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z')
export const DXL_TOKEN_SYMBOL_IMAGE = 'https://dexlab.s3.ap-northeast-2.amazonaws.com/3_GradientSymbol.svg'

export const DXL_STAKE_POOL_V1 = {
  name: 'DXL Stake Season 1',
  regAddress: new PublicKey('BWUPLwsMhCaUaz91eDM8Cb8wfVCfR4QbM9Xe22wRua8D'), // 100DXL, 1week
  poolAddress: new PublicKey('AXYEG84MRYcJoYhJh7Z9btYXdw4G4pdQGYaCubwMkksg'),
  poolDate: '',
  poolApr: 0,
  bootApr: 0,
  minQuantity: 100,
  unlockDay: 7,
  tokenMint: DXL_MINT_ADDRESS,
  tokenIcon: DXL_TOKEN_SYMBOL_IMAGE,
  ended: true,
  view: true,
  desc: `<div>
          <span>
            <b>DXL Stake Season 1</b>
            <br />
            The rewards of staking will happen seasonally, with various benefits. DXL tokens, as rewards, will
            be paid around %+ in consideration of the fee that occurs in Dexlab
          </span>
        </div>
        <br/>
        <div style={{ marginTop: '20px' }}>
          <span><b>Benefits for joining Staking POOL</b></span>
          <br />
          <li>APR during staking</li>
          <li>Guaranteed airdrop event allocation (100%)</li>
          <li>Ticket for Dexlab IDO sale Ranking pool participation</li>
          <li>Increase chances of winning in Dexlab IDO sale Standard pool</li>
          <li>
            <b>Dexlab new service pre-test lottery ticket</b>
          </li>
          <span style={{ fontSize: '12px', color: '#646464', fontStyle: 'italic' }}>
            * Only counts the staking tokens. Exclude pending and available.
          </span>
        </div>`,
}

export const ALL_POOLS = [DXL_STAKE_POOL_V1].filter((f) => f.view)
