import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
// import { useWallet } from '@solana/wallet-adapter-react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Chip from '@mui/material/Chip'
import { ALL_POOLS } from '../../store/pools'
// import { WalletConnectButton } from './Header'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'left',
      margin: '6px',
      width: '100%',
      maxWidth: 1600,
    },
  }),
)

export default function PoolItems() {
  return (
    <>
      <PoolItem />
    </>
  )
}

function PoolItem() {
  // const { wallet } = useWallet()
  const classes = useStyles()
  return (
    <>
      <div>
        {ALL_POOLS.map((pool, idx) => {
          return (
            <Card className={classes.root} key={idx}>
              <CardHeader
                style={{ cursor: 'pointer' }}
                avatar={<Avatar aria-label="recipe" src={pool.tokenIcon} />}
                action={
                  <>
                    <span style={{ fontSize: '15px', fontWeight: 'bold' }}>
                      {!pool.ended ? (
                        <Chip label={`APR ${Number(pool.poolApr) + Number(pool.bootApr)}%`} color="primary" />
                      ) : (
                        <Chip label="Ended" color="success" />
                      )}
                    </span>
                  </>
                }
                title={
                  <>
                    <span style={{ fontSize: '20px' }}>{pool.name}</span>
                  </>
                }
                subheader={pool.poolAddress.toBase58()}
                onClick={() => {
                  window.location.href = `/#/stake?pool=${pool.regAddress.toBase58()}`
                }}
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="div">
                  <div dangerouslySetInnerHTML={{ __html: pool.desc }}></div>
                  {/* {!wallet?.adapter.connected && (
                    <div style={{ marginTop: '10px', textAlign: 'right', marginBottom: '10px' }}>
                      <WalletConnectButton />
                    </div>
                  )} */}
                </Typography>
              </CardContent>
            </Card>
          )
        })}
      </div>
    </>
  )
}
