import React from 'react'

export default function Footer() {
  return (
    <div
      style={{
        zIndex: 99,
        position: 'fixed',
        width: '100%',
        bottom: 0,
        textAlign: 'center',
        height: '30px',
        backgroundColor: '#fbfbfb',
        borderTop: 'solid 1pt #ccc',
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '10px',
        paddingRight: '10px',
      }}
    >
      <div style={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex', marginTop: '5px' }}>
        © Copyright 2022 Dexlab I All Rights Reserved
      </div>
    </div>
  )
}
