import { Wallet, WalletName } from '@solana/wallet-adapter-base'
import { SolletWalletAdapter, SolletWalletAdapterConfig } from './adapter'

export const SolletWalletName = 'Sollet for Dexlab' as WalletName

export const getSolletWallet = ({ provider, ...config }: SolletWalletAdapterConfig = {}): Wallet => ({
  name: SolletWalletName,
  url: 'https://sollet.dexlab.space',
  icon: 'https://raw.githubusercontent.com/dexlab-project/assets/master/dexlab/dexlab_symbol_logo.svg',
  adapter: new SolletWalletAdapter({ provider: provider || 'https://sollet.dexlab.space', ...config }),
})
