import React from 'react'
import { Provider } from 'react-redux'
import { HashRouter, Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'
import { store } from './store'
import WalletProvider from './components/common/WalletProvider'
import BootstrapProvider from './components/common/BootstrapProvider'
import { TokenRegistryProvider } from './utils/tokens'
import Layout from './components/common/Layout'
import MainPage from './pages/Main'
import MyStakePage from './pages/MyStake'
import LeaderBoardPage from './pages/LeaderBoard'

import './css/index.css'

function App() {
  const theme = createMuiTheme({
    palette: {
      type: 'light',
      background: {
        default: 'rgb(255,255,255)',
      },
    },
    typography: {
      fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
    },
    overrides: {},
  })
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={5} autoHideDuration={8000}>
          <TokenRegistryProvider>
            <WalletProvider>
              <BootstrapProvider>
                <HashRouter basename={'/'}>
                  <Layout>
                    <Route exact path="/" component={MainPage} />
                    <Route exact path="/stake" component={MyStakePage} />
                    <Route exact path="/leader-board" component={LeaderBoardPage} />
                  </Layout>
                </HashRouter>
              </BootstrapProvider>
            </WalletProvider>
          </TokenRegistryProvider>
        </SnackbarProvider>
      </MuiThemeProvider>
    </Provider>
  )
}

export default App
