import React, { useEffect, useState } from 'react'
// import { Connection } from '@solana/web3.js'
import queryString from 'query-string'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'
import RefreshIcon from '@mui/icons-material/Refresh'
import LeaderBoardClient from '../../utils/client/LeaderBoardClient'
import { ALL_POOLS, DXL_STAKE_POOL_V1 } from '../../store/pools'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import { numberWithCommasNormal } from '../../utils/utils'
import { Button } from '@material-ui/core'
// import { findOwnedNameAccountsForUser, performReverseLookup } from '../../utils/name-service'

interface Column {
  id: 'rankNo' | 'ownerAddress' | 'stakeBalance' | 'rankDateTime' | 'displayName'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: any) => any
}

export default function LeaderBoard({ isOnlyRank = false }) {
  // const connection = new Connection('https://solana-api.projectserum.com', 'confirmed')
  const { enqueueSnackbar } = useSnackbar()
  const { search } = useLocation()
  const [dxlStakeRanks, setDxlStakeRanks] = useState<any>()
  // const [rankAddressNames, setRankAddressNames] = useState<any[]>([])

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(30)
  const [loadRankData, setLoadRankData] = useState(false)
  const [poolName, setPoolName] = useState('')
  const [poolAddress, setPoolAddress] = useState('')

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // useEffect(() => {
  //   async function getRankDomains() {
  //     if (dxlStakeRanks) {
  //       for(let i=0; i<dxlStakeRanks.ranks.length; i++) {
  //         const domains = await findOwnedNameAccountsForUser(connection, new PublicKey(dxlStakeRanks.ranks[i].ownerAddress))
  //         const names = await performReverseLookup(connection, domains)
  //         const newNames = rankAddressNames
  //         names.forEach((name) => {
  //           newNames.push({
  //             name: name.name,
  //             owner: dxlStakeRanks.ranks[i].ownerAddress,
  //           })
  //         })
  //         console.log(newNames)
  //         setRankAddressNames(newNames)
  //       }
  //     }
  //   }
  //   getRankDomains()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dxlStakeRanks])

  useEffect(() => {
    async function leaderBoardRanks() {
      const parsed = queryString.parse(search)
      const poolAddress = (parsed.pool as string) ?? DXL_STAKE_POOL_V1.regAddress.toBase58()

      const findPool = ALL_POOLS.find((f) => f.regAddress.toBase58() === poolAddress)
      setPoolName(findPool?.name ?? poolAddress)
      setPoolAddress(poolAddress)

      const response = await LeaderBoardClient.getStakeRanks(poolAddress)
      if (response) {
        enqueueSnackbar(`Data has been loaded.`, {
          autoHideDuration: 1500,
          variant: 'success',
        })
        setDxlStakeRanks(response)
        setLoadRankData(true)
      }
    }
    if (!loadRankData) {
      leaderBoardRanks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadRankData])

  const columns: Column[] = [
    {
      id: 'rankNo',
      label: 'Rank',
      minWidth: 170,
      format: (value: any) => {
        if (value === 1) {
          return <span style={{ fontSize: '30px' }}>🥇</span>
        } else if (value === 2) {
          return <span style={{ fontSize: '25px' }}>🥈</span>
        } else if (value === 3) {
          return <span style={{ fontSize: '20px' }}>🥉</span>
        }
        return <span>#{value}</span>
      },
    },
    {
      id: 'displayName',
      label: 'Owner',
      minWidth: 100,
      format: (value: any) => {
        return <span>{value}</span>
      },
    },
    {
      id: 'stakeBalance',
      label: 'Staked Balance',
      minWidth: 170,
      align: 'right',
      format: (value: any) => (
        <>
          <span style={{ fontWeight: 'bold' }}>{numberWithCommasNormal(Number(value).toFixed(0))}</span>
          <span style={{ fontSize: '10px', color: '#646464' }}>DXL</span>
        </>
      ),
    },
    {
      id: 'rankDateTime',
      label: 'Updated(UTC)',
      minWidth: 170,
      align: 'right',
    },
  ]

  return dxlStakeRanks && dxlStakeRanks.ranks ? (
    <>
      <Paper style={{ marginTop: isOnlyRank ? '15px' : 0 }}>
        {!isOnlyRank && (
          <>
            <div style={{ height: '130px', paddingTop: '30px' }}>
              <div>
                <span style={{ fontWeight: 'bold' }}>{poolName}</span> Leader board
              </div>
              <div style={{ fontSize: '13px', color: '#646464' }}>{poolAddress}</div>
              <div style={{ marginTop: '6px' }}>
                <span>Liquidity: {numberWithCommasNormal(Number(dxlStakeRanks.liquidity).toFixed(0))}</span>{' '}
                <span style={{ fontSize: '12px' }}>( {numberWithCommasNormal(dxlStakeRanks.totalCount)}staker )</span>
              </div>
            </div>
          </>
        )}
        <div style={{ textAlign: 'right', paddingRight: '10px', paddingTop: '10px' }}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={() => {
              setLoadRankData(false)
            }}
          >
            Refresh
          </Button>
        </div>

        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dxlStakeRanks.ranks
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, idx: number) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                      {columns.map((column, idx) => {
                        const value = row[column.id]
                        return (
                          <TableCell key={idx} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[30, 100, 500, 1000]}
          component="div"
          count={dxlStakeRanks.ranks.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <div style={{ textAlign: 'right', height: '50px', paddingTop: '10px', fontSize: '13px', paddingRight: '10px' }}>
          Ranking is updated every 15 minutes
        </div>
      </Paper>
    </>
  ) : (
    <>Leaderboard in coming..</>
  )
}
