import { PublicKey } from '@solana/web3.js'
import { DXL_MINT_ADDRESS, DXL_STAKE_POOL_V1 } from './pools'

export const STAKE_GUIDE_LINK = `https://bit.ly/3F2PK1E`
export const IDO_GUIDE_LINK = `https://medium.com/dexlab-official/dexlab-new-ido-rule-b4e53e2c1e5f`

type Networks = { [label: string]: Network }

export type Network = {
  // Cluster.
  label: string
  url: string
  explorerClusterSuffix: string

  // Faucets.
  srmFaucet: PublicKey | null
  msrmFaucet: PublicKey | null

  // Programs.
  registryProgramId: PublicKey
  lockupProgramId: PublicKey
  multisigProgramId: PublicKey

  // Staking instances.
  registrars: { [token: string]: PublicKey }

  // Whitelisted token mints.
  mints: { [token: string]: PublicKey }
}

export const networks: Networks = {
  mainnet: {
    // Cluster.
    label: 'Mainnet Beta',
    url: 'https://withered-skilled-hexagon.solana-mainnet.quiknode.pro/9a2b1301c61db68de656ede0e22c2fd73dfc3d37',
    explorerClusterSuffix: '',

    srmFaucet: null,
    msrmFaucet: null,

    registryProgramId: new PublicKey('GrAkKfEpTKQuVHG2Y97Y2FF4i7y7Q5AHLK94JBy7Y5yv'),
    lockupProgramId: new PublicKey('6ebQNeTPZ1j7k3TtkCCtEPRvG7GQsucQrZ7sSEDQi9Ks'),
    multisigProgramId: new PublicKey('3S6ALMCGVib4X3vVR3CLpm2K6Ng5qbWFYMTo5jfxWcEq'),
    registrars: {
      dxl_stake_v1: DXL_STAKE_POOL_V1.regAddress,
    },
    mints: {
      dxl: DXL_MINT_ADDRESS,
    },
  },
}
