import React, { useEffect } from 'react'
import { PublicKey } from '@solana/web3.js'
import { useSelector, useDispatch } from 'react-redux'
import queryString from 'query-string'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { TokenInfo } from '@solana/spl-token-registry'
import { useTokenInfos } from '../../utils/tokens'
import { State as StoreState, ProgramAccount } from '../../store/reducer'
import { Network } from '../../store/config'
import * as bootstrap from './BootstrapProvider'
import { useWallet } from './WalletProvider'
import { ALL_POOLS, DXL_MINT_ADDRESS, DXL_TOKEN_SYMBOL_IMAGE } from '../../store/pools'
import { useLocation } from 'react-router-dom'

export function activeRegistrar(state: StoreState): { label: string; programAccount: any } {
  const allPools = ALL_POOLS.map((m) => m.regAddress.toBase58())
  const registrars = Object.keys(state.common.network.registrars)
    .map((registrar) => {
      let entry: [string, ProgramAccount] = [
        registrar,
        {
          publicKey: state.common.network.registrars[registrar],
          account: state.accounts[state.common.network.registrars[registrar].toString()],
        },
      ]
      return entry
    })
    .filter((r) => r[1].account !== undefined)
    .filter(([r, acc]) => allPools.includes(acc.publicKey.toBase58()))
  const selectedRegistrar: any = registrars
    .filter(([r, acc]) => acc.publicKey.equals(state.registry.registrar))
    .map(([r, acc]) => {
      return {
        label: r,
        programAccount: acc,
      }
    })
    .pop()
  return selectedRegistrar
}

export default function RegistrarSelect() {
  const allPools = ALL_POOLS.map((m) => m.regAddress.toBase58())
  const { search } = useLocation()

  const { registryClient } = useWallet()
  const { registrars, selectedRegistrar, accounts, network } = useSelector((state: StoreState) => {
    const registrars = Object.keys(state.common.network.registrars)
      .map((registrar) => {
        let entry: [string, ProgramAccount] = [
          registrar,
          {
            publicKey: state.common.network.registrars[registrar],
            account: state.accounts[state.common.network.registrars[registrar].toString()],
          },
        ]
        return entry
      })
      .filter((r) => r[1].account !== undefined)
      .filter(([r, acc]) => allPools.includes(acc.publicKey.toBase58()))
    const selectedRegistrar: any = registrars
      .filter(([r, acc]) => acc.publicKey.equals(state.registry.registrar))
      .map(([r, acc]) => {
        return {
          label: r,
          programAccount: acc,
        }
      })
      .pop()
    return {
      network: state.common.network,
      accounts: state.accounts,
      registrars,
      selectedRegistrar,
    }
  })
  const dispatch = useDispatch()
  const tokenInfos = useTokenInfos()

  useEffect(() => {
    const parsed = queryString.parse(search)
    const poolAddress = parsed.pool as string

    if (poolAddress && registryClient && selectedRegistrar) {
      bootstrap.registrarSwitch(
        registryClient,
        accounts,
        dispatch,
        new PublicKey(poolAddress), // New.
        selectedRegistrar.programAccount.publicKey, // Old.
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Select
      style={{ width: '294px', height: '36px' }}
      variant={'outlined'}
      fullWidth
      value={selectedRegistrar.label}
      onChange={async (e) => {
        console.log(e.target.value)
        const registrar: ProgramAccount = registrars
          .filter(([r, acc]) => r === e.target.value)
          .map(([r, acc]) => acc)
          .pop()!

        bootstrap.registrarSwitch(
          registryClient,
          accounts,
          dispatch,
          registrar!.publicKey, // New.
          selectedRegistrar.programAccount.publicKey, // Old.
        )
      }}
    >
      {registrars.length === 0 ? (
        <MenuItem value={'_loading'}>Loading registrars...</MenuItem>
      ) : (
        registrars.map(([label, registrar]) => {
          const poolInfo = ALL_POOLS.find((f) => f.regAddress.toBase58() === registrar.publicKey.toBase58())
          return (
            <MenuItem key={label} value={label}>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  {getImage(tokenInfos.get(registrar.account.mint.toString()))}
                </div>
                <div
                  style={{
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  {poolInfo?.name ?? registrarToDisplayLabel(registrar, network)}
                </div>
              </div>
            </MenuItem>
          )
        })
      )}
    </Select>
  )
}

function registrarToDisplayLabel(registrar: ProgramAccount, network: Network): string {
  const entry = Object.keys(network.registrars)
    .filter((r) => network.registrars[r].equals(registrar.publicKey))
    .pop()
  if (entry !== undefined) {
    return entry.toUpperCase()
  }
  return registrar.publicKey.toString()
}

export function getImage(token?: TokenInfo, style?: any) {
  if (token) {
    if (token.address === DXL_MINT_ADDRESS.toBase58()) {
      return (
        <img alt="Token Icon" style={style ?? { marginRight: '10px', height: '25px' }} src={DXL_TOKEN_SYMBOL_IMAGE} />
      )
    } else {
      return <img alt="Token Icon" style={style ?? { marginRight: '10px', height: '25px' }} src={token.logoURI} />
    }
  } else {
    return <div style={{ marginLeft: '32px' }}></div>
  }
}
