export function numberWithCommasNormal(n: any) {
  try {
    if (!n || n === 0) {
      return 0
    }
    var parts = n.toString().split('.')
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? '.' + parts[1] : '')
  } catch (e) {
    return n
  }
}
