import React from 'react'
import { useSelector } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Chip from '@mui/material/Chip'
import { State as StoreState } from '../store/reducer'
import { toDisplay } from '../utils/tokens'
import { ALL_POOLS } from '../store/pools'
import { numberWithCommasNormal } from '../utils/utils'
import { STAKE_GUIDE_LINK } from '../store/config'

export default function Me({ dexlabTokenPrice }: { dexlabTokenPrice: number | undefined | null }) {
  const { member } = useSelector((state: StoreState) => {
    return {
      member: state.registry.member,
    }
  })

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ flex: 1, marginTop: '24px', marginBottom: '24px' }}>
        {member ? (
          <MemberBalancesCard dexlabTokenPrice={dexlabTokenPrice} />
        ) : (
          <div style={{ textAlign: 'center', marginTop: '35px' }}>
            Please create a staking account to participate in staking.{' '}
            <a href={STAKE_GUIDE_LINK} target="_blank" rel="noreferrer">
              View Staking Guide
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

// Assumes a member and all vaults is available in the store.
function MemberBalancesCard({ dexlabTokenPrice }: { dexlabTokenPrice: number | undefined | null }) {
  // const { mint, registrar, vault, vaultStake, vaultPw, spt, lockedVault, lockedVaultStake, lockedVaultPw, lockedSpt } =
  const { liquidity, mint, registrar, vault, vaultStake, vaultPw, spt } = useSelector((state: StoreState) => {
    const member = state.accounts[state.registry.member!.toString()]
    const registrar = state.accounts[state.registry.registrar.toString()]
    const mint = state.accounts[registrar.mint.toString()]
    const poolTokenMint = {
      publicKey: registrar.poolMint,
      account: state.accounts[registrar.poolMint.toString()],
    }
    const pricePerShare = toDisplay(registrar.stakeRate, mint.decimals)
    return {
      registrar,
      mint,
      liquidity: numberWithCommasNormal(Number(poolTokenMint.account.supply) * Number(pricePerShare)),
      vault: state.accounts[member.balances.vault],
      vaultStake: state.accounts[member.balances.vaultStake],
      vaultPw: state.accounts[member.balances.vaultPw],
      spt: state.accounts[member.balances.spt],
      lockedVault: state.accounts[member.balancesLocked.vault],
      lockedVaultStake: state.accounts[member.balancesLocked.vaultStake],
      lockedVaultPw: state.accounts[member.balancesLocked.vaultPw],
      lockedSpt: state.accounts[member.balancesLocked.spt],
    }
  })

  const poolInfo = ALL_POOLS.find((f) => f.poolAddress.toBase58() === registrar.poolMint.toBase58())!!
  const tables = [
    {
      title: 'Main Balances',
      description: 'Shows the pool information currently participating.',
      rows: [
        {
          token: poolInfo.name,
          liquidity,
          dexlabTokenPrice,
          balance: toDisplay(vault.amount, mint.decimals),
          stake: toDisplay(vaultStake.amount, mint.decimals),
          pending: toDisplay(vaultPw.amount, mint.decimals),
          spt: toDisplay(spt.amount, 0),
          poolInfo,
        },
      ],
    },
    // {
    //   title: 'Locked Balances',
    //   description:
    //     'Balances deposited from the lockup program. These funds are isolated from the Main Balances and may only be withdrawn back to the lockup program. At all times they are program controlled.',
    //   rows: [
    //     {
    //       token: toDisplayLabel(registrar.mint),
    //       balance: toDisplay(lockedVault.amount, mint.decimals),
    //       stake: toDisplay(lockedVaultStake.amount, mint.decimals),
    //       pending: toDisplay(lockedVaultPw.amount, mint.decimals),
    //       spt: toDisplay(lockedSpt.amount, 0),
    //     },
    //   ],
    // },
  ]
  return (
    <Card
      style={{
        marginBottom: '24px',
      }}
    >
      <CardContent
        style={{
          marginTop: '24px',
          position: 'relative',
          paddingTop: 0,
          paddingBottom: '16px',
        }}
      >
        {tables.map((t) => (
          <BalanceTable key={t.title} title={t.title} description={t.description} rows={t.rows} />
        ))}
      </CardContent>
    </Card>
  )
}

type BalanceTableProps = {
  title: string
  description: string
  rows:
    | null
    | {
        liquidity: string
        dexlabTokenPrice: number | undefined | null
        token: string
        balance: string
        stake: string
        pending: string
        spt: string
        poolInfo: any
      }[]
}

function BalanceTable(props: BalanceTableProps) {
  const { title, rows, description } = props
  return (
    <div style={{ marginBottom: '16px' }}>
      <Typography style={{ fontWeight: 'bold' }}>{title}</Typography>
      <Typography color="textSecondary" style={{ fontSize: '14px' }}>
        {description}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Token</TableCell>
              <TableCell>Pool Liquidity</TableCell>
              <TableCell align="right">Available</TableCell>
              <TableCell align="right">Pending</TableCell>
              <TableCell align="right">Staked</TableCell>
              <TableCell align="right">Pool Token</TableCell>
              <TableCell align="right">APR</TableCell>
              {/* <TableCell align="right">Pool Status</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows === null ? (
              <CircularProgress />
            ) : (
              rows.map((row) => (
                <TableRow key={row.token}>
                  <TableCell component="th" scope="row">
                    <span>
                      <Avatar aria-label="recipe" src={row.poolInfo.tokenIcon} />
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    <span style={{ fontWeight: 'bold', fontSize: '18px' }}>{row.liquidity}</span>
                    <span style={{ fontSize: '10px', color: '#646464', marginLeft: '1px' }}>DXL</span>
                  </TableCell>
                  <TableCell align="right">
                    {numberWithCommasNormal(row.balance)}
                    <span style={{ fontSize: '10px', color: '#646464', marginLeft: '1px' }}>DXL</span>
                  </TableCell>
                  <TableCell align="right">
                    {numberWithCommasNormal(row.pending)}
                    <span style={{ fontSize: '10px', color: '#646464', marginLeft: '1px' }}>DXL</span>
                  </TableCell>
                  <TableCell align="right">
                    {numberWithCommasNormal(row.stake)}
                    <span style={{ fontSize: '10px', color: '#646464', marginLeft: '1px' }}>DXL</span>
                  </TableCell>
                  <TableCell align="right">{numberWithCommasNormal(row.spt)}</TableCell>
                  <TableCell align="right">
                    {Number(row.poolInfo.poolApr) + Number(row.poolInfo.bootApr)}%{' '}
                    {Number(row.poolInfo.bootApr) > 0 && (
                      <>
                        (
                        <Chip
                          size="small"
                          style={{ marginLeft: '3px' }}
                          color="primary"
                          label={`${row.poolInfo.poolApr}%`}
                        />{' '}
                        +{' '}
                        <Chip
                          size="small"
                          style={{ marginLeft: '3px' }}
                          color="secondary"
                          label={`BOOST ${row.poolInfo.bootApr}%`}
                        />
                        )
                      </>
                    )}
                  </TableCell>
                  {/* <TableCell align="right">
                    {row.poolInfo.ended ? (
                      <Chip label="Ended" color="warning" />
                    ) : (
                      <Chip label="Live" color="success" />
                    )}
                  </TableCell> */}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <div style={{ marginTop: '8px', fontSize: '13px' }}>
          <div style={{ marginTop: '6px' }}>
            <li>
              Available:{' '}
              <span style={{ color: '#646464' }}>The number of tokens available for withdrawal and staking</span>
            </li>
            <li>
              Pending:{' '}
              <span style={{ color: '#646464' }}>
                Quantity of unstake tokens in progress (interest not paid, IDO participation not recognized)
              </span>
            </li>
            <li>
              Staked: <span style={{ color: '#646464' }}>Total number of tokens staked</span>
            </li>
            <li>
              Pool Token: <span style={{ color: '#646464' }}>The number of pool tokens you own</span>
            </li>
            <li>
              APR: <span style={{ color: '#646464' }}>Annual Estimated Interest Rate</span>
            </li>
          </div>
          <div style={{ marginTop: '6px' }}>
            <span>
              * Since APR is paid by buying back the fees incurred by Dexlab in DXL, the actual amount of APR displayed
              and the amount paid may be different.
            </span>
            <br />
            <span style={{ fontWeight: 'bold' }}>
              * Boost APR is a limited-time offer and is subject to change at any time
            </span>
          </div>
        </div>
      </TableContainer>
    </div>
  )
}
