import React, { useState, useEffect, ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import RefreshIcon from '@material-ui/icons/Refresh'
import CircularProgress from '@material-ui/core/CircularProgress'
import CallMadeIcon from '@mui/icons-material/CallMade'
import { WalletDialogProvider, WalletDisconnectButton, WalletMultiButton } from './wallet'
import { refreshAccounts } from './BootstrapProvider'
import { State as StoreState, ProgramAccount, BootstrapState } from '../../store/reducer'
import { ActionType } from '../../store/actions'
import { useWallet } from './WalletProvider'

type HeaderProps = {
  isAppReady: boolean
  member?: ProgramAccount
}

export default function Header(props: HeaderProps) {
  const { isAppReady } = props
  const { network } = useSelector((state: StoreState) => {
    return {
      network: state.common.network,
      isAppReady: state.common.isWalletConnected && state.common.bootstrapState === BootstrapState.Bootstrapped,
    }
  })
  const dispatch = useDispatch()
  const location = useLocation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { wallet, registryClient, lockupClient } = useWallet()
  const [isRefreshing, setIsRefreshing] = useState(false)
  return (
    <AppBar
      position="static"
      style={{
        background: '#ffffff',
        color: '#272727',
        boxShadow: 'none',
        borderBottom: 'solid 1pt #ccc',
      }}
    >
      <Toolbar>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex' }}>
            <DexlabLogoButton />
            <BarButton label="Pools" hrefClient="/" bold={location.pathname === '/' ? true : false} />
            {/* <BarButton
              label="LeaderBoard"
              hrefClient="/leader-board"
              bold={location.pathname === '/leader-board' ? true : false}
            /> */}
            {/* <BarButton label="Dexlab" external={true} href="https://www.dexlab.space" /> */}
            {/* <BarButton label="MintingLab" external={true} href="https://minting.dexlab.space" /> */}
            {/* <BarButton label="Analytics" external={true} href="https://info.dexlab.space" /> */}
            {/* <BarButton
              label="Help Center"
              external={true}
              href="https://help.dexlab.space/hc/en-us/sections/4413952185625-Staking"
            /> */}
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              onClick={() => {
                setIsRefreshing(true)
                enqueueSnackbar(`Refreshing`, {
                  variant: 'info',
                })
                refreshAccounts({
                  dispatch,
                  lockupClient,
                  registryClient,
                  network,
                  wallet,
                })
                  .then(() => {
                    setIsRefreshing(false)
                    closeSnackbar()
                  })
                  .catch((err) => {
                    setIsRefreshing(false)
                    closeSnackbar()
                    enqueueSnackbar(`There was a problem refreshing: ${err}`, {
                      variant: 'error',
                      autoHideDuration: 2500,
                    })
                  })
              }}
              style={{
                display: isAppReady ? 'block' : 'none',
                justifyContent: 'center',
                flexDirection: 'column',
                marginRight: '10px',
              }}
            >
              {isRefreshing ? (
                <div
                  style={{
                    marginTop: '8px',
                    padding: '10px',
                  }}
                >
                  <CircularProgress style={{ width: '24px', height: '24px' }} />
                </div>
              ) : (
                <div>
                  <IconButton>
                    <RefreshIcon />
                  </IconButton>
                </div>
              )}
            </div>
            {/* <NetworkSelector /> */}
            <WalletConnectButton />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  )
}

function DexlabLogoButton() {
  const history = useHistory()
  return (
    <div style={{ display: 'flex' }} onClick={() => history.push('/')}>
      <Button color="inherit">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <img
            style={{
              display: 'block',
              height: '35px',
            }}
            alt="Dexlab"
            src="https://raw.githubusercontent.com/dexlab-project/assets/72be46ffd83b6afc5c6fadeef5441d24e373fc5d/dexlab/dexlab_dark_logo.svg"
          />
        </div>
      </Button>
    </div>
  )
}

type BarButtonProps = {
  label: string
  bold?: boolean
  hrefClient?: string
  href?: string
  external?: boolean
}

function BarButton(props: BarButtonProps) {
  const history = useHistory()
  const { label, href, hrefClient, bold } = props
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      onClick={() => hrefClient && history.push(hrefClient)}
    >
      <Link style={{ color: 'inherit', textDecoration: 'none' }} href={href} target="_blank">
        <Button color="inherit">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography style={{ fontSize: '15px', fontWeight: bold ? 'bold' : 'normal', textTransform: 'none' }}>
              {label}
              {props.external && <CallMadeIcon style={{ fontSize: '10px' }} />}
            </Typography>
          </div>
        </Button>
      </Link>
    </div>
  )
}

// function NetworkSelector() {
//   const network = useSelector((state: StoreState) => {
//     return state.common.network
//   })
//   const dispatch = useDispatch()
//   const [anchorEl, setAnchorEl] = useState(null)

//   const handleClose = () => {
//     setAnchorEl(null)
//   }

//   return (
//     <div
//       style={{
//         marginRight: '10px',
//         fontSize: '15px',
//         display: 'flex',
//         justifyContent: 'center',
//         flexDirection: 'column',
//       }}
//     >
//       <Button
//         color="inherit"
//         onClick={(e) =>
//           setAnchorEl(
//             // @ts-ignore
//             e.currentTarget,
//           )
//         }
//       >
//         <BubbleChartIcon />
//         <Typography style={{ marginLeft: '5px', fontSize: '15px' }}>{network.label}</Typography>
//       </Button>
//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleClose}
//         style={{
//           marginLeft: '12px',
//           color: 'white',
//         }}
//       >
//         {Object.keys(networks).map((n: string) => (
//           <MenuItem
//             key={n}
//             onClick={() => {
//               handleClose()
//               dispatch({
//                 type: ActionType.CommonSetNetwork,
//                 item: {
//                   network: networks[n],
//                   networkKey: n,
//                 },
//               })
//             }}
//           >
//             <Typography>{networks[n].label}</Typography>
//           </MenuItem>
//         ))}
//       </Menu>
//     </div>
//   )
// }

export function WalletConnectButton(): ReactElement {
  const dispatch = useDispatch()
  const { wallet, lockupClient } = useWallet()
  const { enqueueSnackbar } = useSnackbar()

  // Wallet connection event listeners.
  useEffect(() => {
    wallet.on('disconnect', () => {
      enqueueSnackbar('Disconnected from wallet', {
        variant: 'info',
        autoHideDuration: 2500,
      })
      dispatch({
        type: ActionType.CommonWalletDidDisconnect,
        item: {},
      })
      dispatch({
        type: ActionType.CommonTriggerShutdown,
        item: {},
      })
    })
    wallet.on('connect', async () => {
      dispatch({
        type: ActionType.CommonWalletDidConnect,
        item: {},
      })
      dispatch({
        type: ActionType.CommonTriggerBootstrap,
        item: {},
      })
    })

    if (wallet && wallet.adapter && !wallet.adapter.connected) {
      wallet.connect()
    }
  }, [wallet, dispatch, enqueueSnackbar, lockupClient.provider.connection])

  return (
    <WalletDialogProvider>
      {wallet.adapter?.connected && (
        <div
          onClick={() => {
            wallet.disconnect()
            window.location.reload()
          }}
          style={{
            // display: 'flex',
            // justifyContent: 'center',
            // flexDirection: 'column',
            marginRight: '3px',
          }}
        >
          <WalletDisconnectButton />
        </div>
      )}
      <div
      // style={{
      //   display: 'flex',
      //   justifyContent: 'center',
      //   flexDirection: 'column',
      // }}
      >
        <WalletMultiButton />
      </div>
    </WalletDialogProvider>
  )
}
