import React, { useState, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
// import FiberNewIcon from '@mui/icons-material/FiberNew'
import { State as StoreState, BootstrapState } from '../../store/reducer'
import Header from './Header'
import Footer from './Footer'
import PoolItems from './PoolItems'
import LeaderBoard from './LeaderBoard'
import { useLocation } from 'react-router-dom'
// import { IDO_GUIDE_LINK } from '../../store/config'

type Props = {}

export default function Layout(props: PropsWithChildren<Props>) {
  const location = useLocation()
  const { isAppReady } = useSelector((state: StoreState) => {
    return {
      isAppReady: state.common.isWalletConnected && state.common.bootstrapState === BootstrapState.Bootstrapped,
    }
  })
  const [refresh, setRefresh] = useState(false)
  return (
    <div
      style={{
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        backgroundColor: 'rgb(251, 251, 251)',
      }}
    >
      <div
        style={{
          position: 'fixed',
          width: '100%',
          zIndex: 99,
        }}
      >
        <NoticeBar />
        <Header isAppReady={isAppReady} />
      </div>
      <div
        style={{
          width: '100%',
          marginTop: '260px',
          flex: 1,
          display: 'flex',
          marginBottom: '30px', // Compensates for the fixed position footer.
        }}
      >
        {window.localStorage.getItem('consent') ? (
          !isAppReady || location.pathname === '/' || location.pathname === '/leader-board' ? (
            <DisconnectedSplash />
          ) : (
            <div style={{ width: '100%' }}>{props.children}</div>
          )
        ) : (
          <RiskDisclosureForm
            onConsent={() => {
              window.localStorage.setItem('consent', 'true')
              setRefresh(!refresh)
            }}
          />
        )}
      </div>
      <Footer />
    </div>
  )
}

function NoticeBar() {
  return (
    <div
      style={{
        color: '#fff',
        backgroundColor: '#E74C3C',
        height: '150px',
        textAlign: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
          cursor: 'pointer',
        }}
      >
        <Typography style={{ fontSize: '17px', marginTop: '10px' }}>
          Dexlab staking service is managed by Project Serum, which can be disabled anytime. Therefore, we recommend
          doing unstaking as soon as possible.
        </Typography>
        <Typography style={{ fontSize: '20px', marginTop: '7px', fontWeight: 'bold' }}>
          As previously announced, this site will no longer support maintenance and customer service from May 1, 2023.
        </Typography>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

function RiskDisclosureForm({ onConsent }: { onConsent: () => void }) {
  return (
    <div
      style={{
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <div style={{ width: '100%', display: 'flex' }}>
        <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}>
          <Typography style={{ marginBottom: '16px', maxWidth: '1000px', fontSize: '21px' }} color="textSecondary">
            <b>The Dexlab DEX is a fully decentralised exchange.</b>
            <br /> No representation or warranty is made concerning any aspect of the Dexlab DEX, including its
            suitability, quality, availability, accessibility, accuracy or safety.
            <br /> Access to and use of the Dexlab DEX is entirely at users’ own risk and could lead to substantial
            losses.
            <br /> Users take full responsibility for their use of the Dexlab DEX, including participation in pools and
            the sale or purchase of any products, including, without limitation, tokens and coins.
            <br />
            <br />
            <b>
              The Dexlab DEX is not available to residents of Belarus, the Central African Republic, the Democratic
              Republic of Congo, the Democratic People’s Republic of Korea, the Crimea region of Ukraine, Cuba, Iran,
              Libya, Somalia, Sudan, South Sudan, Syria, the USA, Yemen, and Zimbabwe or any other jurisdiction in which
              accessing or using the Dexlab DEX is prohibited (“Prohibited Jurisdiction").
              <br />
              In using the exchange, you confirm that you do not reside in a Prohibited Jurisdiction.
            </b>
            <br />
            <br />
            DXL tokens are not available to residents of Prohibited Jurisdictions.
            <br />
            <br />
            Cookies are used to enhance the services available to you.
            <br />
            You agree to the use of cookies.
            <br />
          </Typography>
        </div>
      </div>
      <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}>
        <Button variant="contained" color="primary" onClick={onConsent}>
          I agree
        </Button>
      </div>
    </div>
  )
}

function DisconnectedSplash() {
  const location = useLocation()
  const classes = useStyles()
  const { isDisconnected } = useSelector((state: StoreState) => {
    return {
      network: state.common.network,
      isDisconnected: !state.common.isWalletConnected,
    }
  })

  return (
    <div
      style={{
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <div style={{ width: '100%', display: 'flex' }}>
        {location.pathname === '/' || location.pathname === '/leader-board' ? (
          <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto', fontSize: '20px' }}>
            {location.pathname === '/' ? (
              <PoolItems />
            ) : location.pathname === '/leader-board' ? (
              <LeaderBoard />
            ) : (
              <>A wallet connection is required.</>
            )}
          </div>
        ) : isDisconnected ? (
          <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto', fontSize: '20px' }}>
            A wallet connection is required.
          </div>
        ) : (
          <div style={{ width: '100%' }}>
            <div style={{ width: '100%' }} className={classes.root}>
              <div>
                <Typography variant="h5">{`Loading pool...`}</Typography>
              </div>
              <div
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '300px',
                }}
              >
                <LinearProgress style={{ width: '100%' }} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
