export default class LeaderBoardClient {
  static URL: string = 'https://stake-api.dexlab.space'

  static async get(path: string) {
    try {
      const response = await fetch(this.URL + path)
      if (response.ok) {
        return response.json()
      }
    } catch (err: any) {
      console.log(`Error LeaderBoardClient API ${path}: ${err}`)
    }
    return null
  }

  static async getStakeRanks(regAddress: string): Promise<any | null> {
    return LeaderBoardClient.get(`/v1/stake/${regAddress}`)
  }
}
